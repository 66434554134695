import React from 'react';

export default function InputError({
    message,
    className,
    children,
}) {
    if (!message && !children) {
        return null;
    }
    return (
        <div className={className}>
            <p className="text-sm text-danger">
                {message || children}
            </p>
        </div>
    );
}
