import { useParams } from "react-router-dom";

function Invite(){
    const { link } = useParams();
    localStorage.setItem("invite-code",link);
    window.location.href="/profile";
return(
    <>
   
    </>
)
}
export default Invite;