import { useEffect, useState } from "react"
import Sectionheader from "./Sectionheader";
import apiService from "../../apiService";
function History(){

const [history_data, sethistory_data] = useState([]);

useEffect(()=>{
    get_user_order_history();
},[])

const get_user_order_history = async()=>{
    const data = await apiService.get_user_history_data();
    console.log(data);
    sethistory_data(data.data);
}

    return(
        <>
                    <div className="col-md-8 ">
                <div className="profile_content">
                    <Sectionheader />
                    <div>
                        <h2 className="text-center m-4">Your Order History</h2>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Service Booking ID</th>
                                    <th>Amount</th>
                                    
                                    <th>Service Status</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {history_data && history_data.map((his,index)=>{
                                    return(<>
                                    <tr>
                                    <td>{his.booking_id}</td>
                                    <td>&#8377;{his.booking_amount}</td>
                                    <td>{his.booking_status==6?"Pending":""} {his.booking_status==1?"Confirmed":""}</td>
                                </tr>
                                    </>)
                                })}
                                
                                
                                {/* Add more rows for additional products */}
                            </tbody>
                        </table>
                        </div>
                        </div>
                        </div>
        </>
    )
}

export default History;