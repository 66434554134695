import React, { useEffect, useState } from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import apiService from '../apiService';

function Home() {

    const [categories, setcategories] = useState([]);

    useEffect(()=>{
   get_categories()
    },[])

    const get_categories = async()=>{
        const data = await apiService.getcategories()
        setcategories(data.data.data);
        
    }
    return (
        <>
            <Navbar />
            <Header />
            {/* <section className="booking_area">
                <div className="container">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button
                                className="nav-link active"
                                id="nav-train-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-train"
                                type="button"
                                role="tab"
                                aria-controls="nav-train"
                                aria-selected="true"
                            >
                                <img src="assets/images/train_Icon.png" alt="" /> Book Trains
                            </button>
                            <button
                                className="nav-link"
                                id="nav-flight-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-flight"
                                type="button"
                                role="tab"
                                aria-controls="nav-flight"
                                aria-selected="false"
                            >
                                <img src="assets/images/flight_Icon.png" alt="" /> Book Flight
                            </button>
                            <button
                                className="nav-link"
                                id="nav-bus-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-bus"
                                type="button"
                                role="tab"
                                aria-controls="nav-bus"
                                aria-selected="false"
                            >
                                <img src="assets/images/bus_Icon.png" alt="" />
                                Book Bus
                            </button>
                            <button
                                className="nav-link"
                                id="nav-hotel-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-hotel"
                                type="button"
                                role="tab"
                                aria-controls="nav-hotel"
                                aria-selected="false"
                            >
                                <img src="assets/images/hotel_Icon.png" alt="" /> Hotels
                            </button>
                            <button
                                className="nav-link"
                                id="nav-holiday-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-holiday"
                                type="button"
                                role="tab"
                                aria-controls="nav-holiday"
                                aria-selected="false"
                            >
                                <img src="assets/images/holiday_Icon.png" alt="" /> Holidays
                            </button>
                            <button
                                className="nav-link"
                                id="nav-groupT-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-groupT"
                                type="button"
                                role="tab"
                                aria-controls="nav-groupT"
                                aria-selected="false"
                            >
                                <img src="assets/images/groupt_Icon.png" alt="" /> Group Trains
                                Booking
                            </button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="nav-train"
                            role="tabpanel"
                            aria-labelledby="nav-train-tab"
                            tabIndex={0}
                        >
                            <div className="whIteWrappr">
                                <form className="banner-form">
                                    <div className="row align-items-center">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">From</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    placeholder="Howrah Jn -HWH"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail2">To</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="exampleInputEmail2"
                                                    placeholder="Goa Madgaon - MAO"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail2">Depart</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="exampleInputEmail2"
                                                    placeholder="Wed, 17 Jan"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <button type="submit" className="find-btn">
                                                Search Trains
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="nav-flight"
                            role="tabpanel"
                            aria-labelledby="nav-flight-tab"
                            tabIndex={0}
                        >
                            B
                        </div>
                        <div
                            className="tab-pane fade"
                            id="nav-bus"
                            role="tabpanel"
                            aria-labelledby="nav-bus-tab"
                            tabIndex={0}
                        >
                            C
                        </div>
                        <div
                            className="tab-pane fade"
                            id="nav-hotel"
                            role="tabpanel"
                            aria-labelledby="nav-hotel-tab"
                            tabIndex={0}
                        >
                            d
                        </div>
                        <div
                            className="tab-pane fade"
                            id="nav-holiday"
                            role="tabpanel"
                            aria-labelledby="nav-holiday-tab"
                            tabIndex={0}
                        >
                            e
                        </div>
                        <div
                            className="tab-pane fade"
                            id="nav-groupT"
                            role="tabpanel"
                            aria-labelledby="nav-groupT-tab"
                            tabIndex={0}
                        >
                            f
                        </div>
                    </div>
                </div>
            </section> */}
            <div className="bankarea">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ReactOwlCarousel
                                className="owl-theme owl-carousel-bank"
                                loop={true}
                                nav={false}
                                dots={false}
                                margin={20}
                                padding={20}
                                autoplay={true}
                                smartSpeed={2000}
                                center={false}
                                navText={[
                                    '<i class="fa-solid fa-angle-left"></i>',
                                    '<i class="fa-solid fa-arrow-right"></i>'
                                ]}
                                responsive={{
                                    0: {
                                        items: 1.2,
                                    },
                                    578: {
                                        items: 2.2,
                                    },
                                    768: {
                                        items: 2.2,
                                    },
                                    992: {
                                        items: 3.2,
                                    },
                                    1200: {
                                        items: 3.2,
                                    },
                                    1300: {
                                        items: 3.2,
                                    },
                                }}
                            >
                                <div className="single">
                                    <div className="bankItem">
                                        <img src="assets/images/bank_one.png" alt="" />
                                        <p>
                                            ICICI Bank offer 3,000 off
                                            <span>On International Booking</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="bankItem">
                                        <img src="assets/images/bank_two.png" alt="" />
                                        <p>
                                            IDFC FIRST offer 1,000 off
                                            <span>On Domesic Booking</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="bankItem">
                                        <img src="assets/images/bank_three.png" alt="" />
                                        <p>
                                            HDFC Bank offer 2,500 off
                                            <span>On International Booking</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="bankItem">
                                        <img src="assets/images/bank_one.png" alt="" />
                                        <p>
                                            ICICI Bank offer 3,000 off
                                            <span>On International Booking</span>
                                        </p>
                                    </div>
                                </div>
                            </ReactOwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
            <section className="segmentArea">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <ReactOwlCarousel
                                className="owl-theme owl-carousel-addplay"
                                loop={true}
                                nav={false}
                                dots={true}
                                margin={20}
                                padding={20}
                                autoplay={true}
                                smartSpeed={2000}
                                center={false}
                                navText={[
                                    '<i class="fa-solid fa-angle-left"></i>',
                                    '<i class="fa-solid fa-arrow-right"></i>'
                                ]}
                                responsive={{
                                    0: {
                                        items: 1,
                                    },
                                    578: {
                                        items: 1,
                                    },
                                    768: {
                                        items: 1,
                                    },
                                    992: {
                                        items: 1,
                                    },
                                    1200: {
                                        items: 1,
                                    },
                                    1300: {
                                        items: 1,
                                    },
                                }}
                            >
                                <div className="single">
                                    <div className="item-box">
                                        <div className="add-box">
                                            <img src="assets/images/adtwo.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="item-box">
                                        <div className="add-box">
                                            <img src="assets/images/adtwo.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="item-box">
                                        <div className="add-box">
                                            <img src="assets/images/adtwo.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="item-box">
                                        <div className="add-box">
                                            <img src="assets/images/adtwo.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="item-box">
                                        <div className="add-box">
                                            <img src="assets/images/adtwo.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </ReactOwlCarousel>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="expBox">
                                        <img
                                            className="img-fluid"
                                            src="assets/images/expImage_one.png"
                                            alt=""
                                        />
                                        <Link to="/service/fashion" className="exppopbtn">
                                            {' '}
                                            <span>Explore</span>{' '}
                                            <i className="fa-solid fa-angle-right ms-2"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="expBox">
                                        <img
                                            className="img-fluid"
                                            src="assets/images/expImage_two.png"
                                            alt=""
                                        />
                                        <Link to="/service/hotel-bookiing" className="exppopbtn">
                                            {' '}
                                            <span>Explore</span>{' '}
                                            <i className="fa-solid fa-angle-right ms-2"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="expBox">
                                        <img
                                            className="img-fluid"
                                            src="assets/images/expImage_one.png"
                                            alt=""
                                        />
                                        <Link to="/service/fashion" className="exppopbtn">
                                            {' '}
                                            <span>Explore</span>{' '}
                                            <i className="fa-solid fa-angle-right ms-2"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our_servces">
                <div className="container">
                    <h3>Our Services</h3>
                    <div className="row">
                        

                              {categories && categories.map((category,index)=>{
                                return(
                                    <>
                                    <div className="col-md-2 col-sm-6">
                                    <Link to={`/service/${category.slug}`}  style={{textDecoration:"none"}}>
                                        <div className="servicebox" key={index}>
                                    <div className="serviceboxInner">
                                        <img src="assets/images/service_one.png" alt="" />
                                    </div>
                                    <h6>{category.name}</h6>
                                </div>
                               
                                </Link>
                                 </div>
                                    </>
                                )
                              })}
                            

                            
                              

                        

                        <div class="col-md-2 col-sm-6">
  <Link to="/services" style={{"textDecoration":"none"}}>
                                <div className="servicebox">
                                    <div className="serviceboxInner VWAll">
                                        <img src="assets/images/grid.png" alt="" />
                                    </div>
                                    <h6> View All</h6>
                                </div>
                                </Link>

                        </div>
                    </div>
                </div>
            </section>
            <div className="kid-fashion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-inner">
                                <div className="row align-items-center">
                                    <div className="col-md-4 buy-sell-left">
                                        <h2 className="title-one">
                                            Doctor
                                            <p className="mt-2">
                                                We Provide all Types of medical doctor
                                            </p>
                                        </h2>
                                        <Link to="">Explore More</Link>
                                    </div>
                                    <div className="col-md-8">
                                   
        <ReactOwlCarousel
            className="owl-theme"
            loop={true}
                                            nav={true}
                                            dots={false}
                                            margin={20}
                                            padding={20}
                                            autoplay={true}
                                            smartSpeed={2000}
                                            center={false}
                                            navText={[
                                                '<i class="fa-solid fa-angle-left"></i>',
                                                '<i class="fa-solid fa-arrow-right"></i>'
                                            ]}
                                            autoplayHoverPause={true}
            responsive={{
                0: {
                    items: 2,
                },
                600: {
                    items: 4,
                },
                1000: {
                    items: 5,
                }
            }}
        >
            <div className="item">
                <Link to="/service/doctor" style={{ textDecoration: "none" }}>
                    <div className="screen-box-outer">
                        <div className="screen-box">
                            <img src="assets/images/doct_one.png" alt="" />
                        </div>
                        <span>Bones & Joints Doctors</span>
                    </div>
                </Link>
            </div>

            <div className="item">
                <Link to="/service/doctor" style={{ textDecoration: "none" }}>
                    <div className="screen-box-outer">
                        <div className="screen-box">
                            <img src="assets/images/doct_three.png" alt="" />
                        </div>
                        <span>EYE Specialists Doctors</span>
                    </div>
                </Link>
            </div>

            <div className="item">
                <Link to="/service/doctor" style={{ textDecoration: "none" }}>
                    <div className="screen-box-outer">
                        <div className="screen-box">
                            <img src="assets/images/doct_Four.png" alt="" />
                        </div>
                        <span>Child Specialist Doctors</span>
                    </div>
                </Link>
            </div>
        </ReactOwlCarousel>


                                    </div>
                                </div>
                            </div>
                            <div className="box-inner">
                                <div className="row align-items-center">
                                    <div className="col-md-4 buy-sell-left">
                                        <h2 className="title-one">
                                            Home Service
                                            <p className="mt-2">
                                                We can provide you with very important service contact
                                                on home service.
                                            </p>
                                        </h2>
                                        <Link to="">Explore More</Link>
                                    </div>
                                    <div className="col-md-8">
                                        <ReactOwlCarousel
                                            className="owl-theme"
                                            loop={true}
                                            nav={true}
                                            dots={false}
                                            margin={20}
                                            padding={20}
                                            autoplay={true}
                                            smartSpeed={2000}
                                            center={false}
                                            navText={[
                                                '<i class="fa-solid fa-angle-left"></i>',
                                                '<i class="fa-solid fa-arrow-right"></i>'
                                            ]}
                                            autoplayHoverPause={true}
                                            responsive={{
                                                0: {
                                                    items: 2,
                                                },
                                                578: {
                                                    items: 2,
                                                },
                                                768: {
                                                    items: 3,
                                                },
                                                992: {
                                                    items: 4,
                                                },
                                                1200: {
                                                    items: 5,
                                                },
                                                1300: {
                                                    items: 5,
                                                },
                                            }}
                                        >
                                            <div className="item">

                                                <Link to="/service/basic-computer" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/home_one.png" alt="" />
                                                        </div>
                                                        <span>Basic Computer</span>
                                                    </div>

</Link>
</div>
<div className="item">
<Link to="/service/computer-hardware" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/home_two.png" alt="" />
                                                        </div>
                                                        <span>Computer Hardware</span>
                                                    </div>
                                                    </Link>
                                                    </div>
                                                    <div className="item">
                                                    <Link to="/service/computer-network" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/home_three.png" alt="" />
                                                        </div>
                                                        <span>Computer Network</span>
                                                    </div>
</Link>
</div>
<div className="item">
<Link to="/service/database" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/home_four.png" alt="" />
                                                        </div>
                                                        <span>Database</span>
                                                    </div>

                                                    </Link>
                                                    </div>
                                                    <div className="item">
                                                    <Link to="/service/mobile-development" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/home_five.png" alt="" />
                                                        </div>
                                                        <span>Mobile Development</span>
                                                    </div>
</Link>
</div>
                                                
                                         
                                        </ReactOwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="segmentArea">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <ReactOwlCarousel
                                className="owl-theme  owl-carousel-addplay"
                                loop={true}
                                nav={false}
                                dots={true}
                                margin={20}
                                padding={20}
                                autoplay={true}
                                smartSpeed={2000}
                                center={false}
                                navText={[
                                    '<i class="fa-solid fa-angle-left"></i>',
                                    '<i class="fa-solid fa-arrow-right"></i>'
                                ]}
                                responsive={{
                                    0: {
                                        items: 1,
                                    },
                                    578: {
                                        items: 1,
                                    },
                                    768: {
                                        items: 1,
                                    },
                                    992: {
                                        items: 1,
                                    },
                                    1200: {
                                        items: 1,
                                    },
                                    1300: {
                                        items: 1,
                                    },
                                }}
                            >
                                <div className="single">
                                    <div className="item-box">
                                        <div className="add-box">
                                            <img
                                                src="assets/images/travel_bookingposter.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="item-box">
                                        <div className="add-box">
                                            <img
                                                src="assets/images/travel_bookingposter.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="item-box">
                                        <div className="add-box">
                                            <img
                                                src="assets/images/travel_bookingposter.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="item-box">
                                        <div className="add-box">
                                            <img
                                                src="assets/images/travel_bookingposter.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="item-box">
                                        <div className="add-box">
                                            <img src="assets/images/adone.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </ReactOwlCarousel>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="expBox">
                                        <img
                                            className="img-fluid"
                                            src="assets/images/expImage_one.png"
                                            alt=""
                                        />
                                        <Link to="/service/fashion" className="exppopbtn">
                                            {' '}
                                            <span>Explore</span>{' '}
                                            <i className="fa-solid fa-angle-right ms-2"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="expBox">
                                        <img
                                            className="img-fluid"
                                            src="assets/images/expImage_two.png"
                                            alt=""
                                        />
                                        <Link to="service/hotel-booking" className="exppopbtn">
                                            {' '}
                                            <span>Explore</span>{' '}
                                            <i className="fa-solid fa-angle-right ms-2"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="expBox">
                                        <img
                                            className="img-fluid"
                                            src="assets/images/expImage_one.png"
                                            alt=""
                                        />
                                        <Link to="/service/fashion" className="exppopbtn">
                                            {' '}
                                            <span>Explore</span>{' '}
                                            <i className="fa-solid fa-angle-right ms-2"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="kid-fashion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-inner">
                                <div className="row align-items-center">
                                    <div className="col-md-4 buy-sell-left">
                                        <h2 className="title-one">
                                            Buy & Sell
                                            <p>
                                                <strong>All Categories of tools</strong>
                                            </p>
                                            <p className="mt-2">
                                                You can Buy and sell through Relief app and get many
                                                benefits
                                            </p>
                                        </h2>
                                        <Link to="">Explore More</Link>
                                    </div>
                                    <div className="col-md-8">
                                        <ReactOwlCarousel
                                            className="owl-theme"
                                            loop={true}
                                            nav={true}
                                            dots={false}
                                            margin={20}
                                            padding={20}
                                            autoplay={true}
                                            smartSpeed={2000}
                                            center={false}
                                            navText={[
                                                '<i class="fa-solid fa-angle-left"></i>',
                                                '<i class="fa-solid fa-arrow-right"></i>'
                                            ]}
                                            autoplayHoverPause={true}
                                            responsive={{
                                                0: {
                                                    items: 2,
                                                },
                                                578: {
                                                    items: 2,
                                                },
                                                768: {
                                                    items: 3,
                                                },
                                                992: {
                                                    items: 4,
                                                },
                                                1200: {
                                                    items: 5,
                                                },
                                                1300: {
                                                    items: 5,
                                                },
                                            }}
                                        >
                                         
                                                <div className="item">

                                                  <Link to="/service/mobile" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/buysell_one.png" alt="" />
                                                        </div>
                                                        <span>Mobile</span>
                                                    </div>

                                                    </Link>
                                                    </div>
                                                    <div className="item">
                                                      <Link to="/service/basic-computer" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/buysell_two.png" alt="" />
                                                        </div>
                                                        <span>Furniture</span>
                                                    </div>
                                                    </Link>
                                                    </div>
                                                    <div className="item">

                                                      <Link to="/service/cars" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img
                                                                src="assets/images/buysell_three.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <span>Cars(Autos)</span>
                                                    </div>

                                                    </Link>
                                                    </div>
                                                    <div className="item">
                                                      <Link to="/service/bike" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img
                                                                src="assets/images/buysell_four.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <span>Bike</span>
                                                    </div>
</Link>
</div>
<div className="item">
  <Link to="/service/fashion" style={{"textDecoration":"none"}}>

                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img
                                                                src="assets/images/buysell_five.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <span>Fashion</span>
                                                    </div>
</Link>
</div>
                                               
                                        
                                        </ReactOwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="servicesBoxCards">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="servicards">
                                <h5>Repairs & Service</h5>
                                <div className="TyPeList">
                                    <figure className="figure">
                                      <Link to="/service/ac-repairs" style={{"textDecoration":"none"}}>
                                        <img
                                            src="assets/images/supImage_one.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">
                                            AC repairs
                                        </figcaption>
                                        </Link>
                                    </figure>
                                    <figure className="figure">
                                      <Link to="/service/tv-repairs" style={{"textDecoration":"none"}}>
                                        <img
                                            src="assets/images/supImage_two.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">
                                            TV repairs
                                        </figcaption>
</Link>

                                    </figure>
                                    <figure className="figure">
                                      <Link to="/service/washing-machine" style={{"textDecoration":"none"}}>
                                        <img
                                            src="assets/images/supImage_three.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">
                                            Washing machine
                                        </figcaption>
                                        </Link>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="servicards">
                                <h5>Home Service</h5>
                                <div className="TyPeList">
                                    <figure className="figure">
                                      <Link to="/service/plumber" style={{"textDecoration":"none"}}>
                                        <img
                                            src="assets/images/supImage_four.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">Plumber</figcaption>
                                        </Link>
                                    </figure>
                                    <figure className="figure">
                                      <Link to="/service/electrical" style={{"textDecoration":"none"}}>
                                        <img
                                            src="assets/images/supImage_five.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">
                                            Electrical
                                        </figcaption>
                                        </Link>
                                    </figure>
                                    <figure className="figure">
                                      <Link to="/service/carpenter" style={{"textDecoration":"none"}}>
                                        <img
                                            src="assets/images/supImage_six.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">
                                            Carpenter
                                        </figcaption>
                                        </Link>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="servicards">
                                <h5>Beauty</h5>
                                <div className="TyPeList">
                                    <figure className="figure">
                                      <Link to="/service/beauty-palouse" style={{"textDecoration":"none"}}>
                                        <img
                                            src="assets/images/supImage_seven.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">
                                            Beauty Palouse
                                        </figcaption>
                                        </Link>
                                    </figure>
                                    <figure className="figure">
                                      <Link to="/service/bridal-makeup" style={{"textDecoration":"none"}}>
                                        <img
                                            src="assets/images/supImage_eight.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">
                                            Bridal Makeup
                                        </figcaption></Link>
                                    </figure>
                                    <figure className="figure">
                                      <Link to="/service/spas" style={{"textDecoration":"none"}}>
                                        <img
                                            src="assets/images/supImage_nine.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">Spas</figcaption>
                                        </Link>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="servicards">
                                <h5>Bill & Recharge</h5>
                                <div className="TyPeList">
                                    <figure className="figure">
                                        <img
                                            src="assets/images/supImage_ten.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">
                                            Mobile recharge
                                        </figcaption>
                                    </figure>
                                    <figure className="figure">
                                        <img
                                            src="assets/images/supImage_eleven.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">
                                            Electricity bill
                                        </figcaption>
                                    </figure>
                                    <figure className="figure">
                                        <img
                                            src="assets/images/supImage_twelve.png"
                                            className="figure-img img-fluid"
                                            alt="..."
                                        />
                                        <figcaption className="figure-caption">
                                            Broadband bill
                                        </figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <div className="kid-fashion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-inner">
                                <div className="row align-items-center">
                                    <div className="col-md-4 buy-sell-left">
                                        <h2 className="title-one">
                                            Bill & Recharge
                                            <p className="mt-2">
                                                Pay your bills & recharge instantly with Reliep
                                            </p>
                                        </h2>
                                        <Link to="">Explore More</Link>
                                    </div>
                                    <div className="col-md-8">
                                        <ReactOwlCarousel
                                            className="owl-theme"
                                            loop={true}
                                            nav={true}
                                            dots={false}
                                            margin={20}
                                            padding={20}
                                            autoplay={true}
                                            smartSpeed={2000}
                                            center={false}
                                            navText={[
                                                '<i class="fa-solid fa-angle-left"></i>',
                                                '<i class="fa-solid fa-arrow-right"></i>'
                                            ]}
                                            autoplayHoverPause={true}
                                            responsive={{
                                                0: {
                                                    items: 2,
                                                },
                                                578: {
                                                    items: 3,
                                                },
                                                768: {
                                                    items: 3,
                                                },
                                                992: {
                                                    items: 4,
                                                },
                                                1200: {
                                                    items: 5,
                                                },
                                                1300: {
                                                    items: 5,
                                                },
                                            }}
                                        >
                                           
                                                <div className="item">
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/bill_one.png" alt="" />
                                                        </div>
                                                        <span>Mobile Recharge</span>
                                                    </div>
                                                    </div>

                                                    <div className="item">
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/bill_two.png" alt="" />
                                                        </div>
                                                        <span>Electricity Bill</span>
                                                    </div>
                                                    </div>
                                                    <div className="item">


                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/bill_three.png" alt="" />
                                                        </div>
                                                        <span>Cable TV Bill</span>
                                                    </div>
                                                    </div>

                                                    <div className="item">


                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/bill_four.png" alt="" />
                                                        </div>
                                                        <span>Gas Bill</span>
                                                    </div>

                                                    </div>

                                                    <div className="item">
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/bill_five.png" alt="" />
                                                        </div>
                                                        <span>Broadband Bill</span>
                                                    </div>
                                                    </div>


                                                
                                          
                                        </ReactOwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div > */}
            <div className="kid-fashion">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-inner">
                                <div className="row align-items-center">
                                    <div className="col-md-4 buy-sell-left">
                                        <h2 className="title-one">
                                            Kids Fashion
                                            <p className="mt-2">
                                                Here you will find all kinds off kids stuff
                                            </p>
                                        </h2>
                                        <Link to="">Explore More</Link>
                                    </div>
                                    <div className="col-md-8">
                                        <ReactOwlCarousel
                                            className="owl-theme"
                                            loop={true}
                                            nav={true}
                                            dots={false}
                                            margin={20}
                                            padding={20}
                                            autoplay={true}
                                            smartSpeed={2000}
                                            center={false}
                                            navText={[
                                                '<i class="fa-solid fa-angle-left"></i>',
                                                '<i class="fa-solid fa-arrow-right"></i>'
                                            ]}
                                            autoplayHoverPause={true}
                                            responsive={{
                                                0: {
                                                    items: 2,
                                                },
                                                578: {
                                                    items: 3,
                                                },
                                                768: {
                                                    items: 3,
                                                },
                                                992: {
                                                    items: 4,
                                                },
                                                1200: {
                                                    items: 5,
                                                },
                                                1300: {
                                                    items: 5,
                                                },
                                            }}
                                        >
                                           
                                                <div className="item">
                                                  <Link to="/service/baby-food" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/fashion_one.png" alt="" />
                                                        </div>
                                                        <span>Baby Food</span>
                                                    </div></Link>
                                                    </div>
                                                    <div className="item">
                                                      <Link to="/service/baby-clothes" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/fashion_two.png" alt="" />
                                                        </div>
                                                        <span>Baby Clothes</span>
                                                    </div>
                                                    </Link>
                                                    </div>
                                                    <div className="item">
                                                      <Link to="/service/baby-footware" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img
                                                                src="assets/images/fashion_three.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <span>Baby Footwear</span>
                                                    </div>
                                                    </Link>
                                                    </div>
                                                    <div className="item">
                                                      <Link to="/service/baby-diapers" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img
                                                                src="assets/images/fashion_four.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <span>Baby Diapers</span>
                                                    </div>
                                                    </Link>
                                                    </div>
                                                    <div className="item">
                                                      <Link to="/service/baby-creams" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img
                                                                src="assets/images/fashion_five.png"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <span>Baby Creams</span>
                                                    </div>

                                                    </Link>
                                                    </div>




                                                
                                            
                                           
                                        </ReactOwlCarousel>
                                    </div>
                                </div>
                            </div>
                            <div className="box-inner">
                                <div className="row align-items-center">
                                    <div className="col-md-4 buy-sell-left">
                                        <h2 className="title-one">
                                            Training Institutes
                                            <p className="mt-2">
                                                From this platform we provide you many trainings on
                                                computer basics and computer hardworking
                                            </p>
                                        </h2>
                                        <Link to="">Explore More</Link>
                                    </div>
                                    <div className="col-md-8">
                                        <ReactOwlCarousel
                                            className="owl-theme"
                                            loop={true}
                                            nav={true}
                                            dots={false}
                                            margin={20}
                                            padding={20}
                                            autoplay={true}
                                            smartSpeed={2000}
                                            center={false}
                                            navText={[
                                                '<i class="fa-solid fa-angle-left"></i>',
                                                '<i class="fa-solid fa-arrow-right"></i>'
                                            ]}
                                            autoplayHoverPause={true}
                                            responsive={{
                                                0: {
                                                    items: 2,
                                                },
                                                578: {
                                                    items: 3,
                                                },
                                                768: {
                                                    items: 3,
                                                },
                                                992: {
                                                    items: 4,
                                                },
                                                1200: {
                                                    items: 5,
                                                },
                                                1300: {
                                                    items: 5,
                                                },
                                            }}
                                        >
                                            
                                                <div className="item">

                                                <Link to="/service/basic-computer" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/home_one.png" alt="" />
                                                        </div>
                                                        <span>Basic Computer</span>
                                                    </div>

</Link>
</div>
<div className="item">

<Link to="/service/computer-hardware" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/home_two.png" alt="" />
                                                        </div>
                                                        <span>Computer Hardware</span>
                                                    </div>
                                                    </Link>
                                                    </div>
                                                    <div className="item">
                                                    <Link to="/service/computer-network" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/home_three.png" alt="" />
                                                        </div>
                                                        <span>Computer Network</span>
                                                    </div>
</Link>
</div>
<div className="item">
<Link to="/service/database" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/home_four.png" alt="" />
                                                        </div>
                                                        <span>Database</span>
                                                    </div>

                                                    </Link>
</div>
<div className="item">
                                                    <Link to="/service/mobile-development" style={{"textDecoration":"none"}}>
                                                    <div className="screen-box-outer">
                                                        <div className="screen-box">
                                                            <img src="assets/images/home_five.png" alt="" />
                                                        </div>
                                                        <span>Mobile Development</span>
                                                    </div>
</Link>
</div>
                                               
                                            
                                        </ReactOwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <div className="ExploreArea">
                <div className="container">
                    <div className="ExploreAreaInner">
                        <div className="row">
                            <div className="ExploreAreaInnerLeft col-md-5">
                                <h3>
                                    Relep Rewards <span>Get Rewarded, everytime!</span>
                                </h3>
                                <p>Reliep reward point on every transaction.</p>
                                <Link to="" className="explore">
                                    Explore now
                                </Link>
                            </div>
                            <div className="ExploreAreaInnerRight col-md-7">
                                <ReactOwlCarousel
                                    className="owl-theme owl-carousel-addplay"
                                    loop={true}
                                    nav={false}
                                    dots={true}
                                    margin={20}
                                    padding={20}
                                    autoplay={true}
                                    smartSpeed={2000}
                                    center={false}
                                    navText={[
                                        '<i class="fa-solid fa-angle-left"></i>',
                                        '<i class="fa-solid fa-arrow-right"></i>'
                                    ]}
                                    responsive={{
                                        0: {
                                            items: 1,
                                        },
                                        578: {
                                            items: 1,
                                        },
                                        768: {
                                            items: 1,
                                        },
                                        992: {
                                            items: 1,
                                        },
                                        1200: {
                                            items: 1,
                                        },
                                        1300: {
                                            items: 1,
                                        },
                                    }}
                                >
                                    <div className="single">
                                        <div className="item-box">
                                            <div className="add-box">
                                                <img src="assets/images/adone.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single">
                                        <div className="item-box">
                                            <div className="add-box">
                                                <img src="assets/images/adone.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single">
                                        <div className="item-box">
                                            <div className="add-box">
                                                <img src="assets/images/adone.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single">
                                        <div className="item-box">
                                            <div className="add-box">
                                                <img src="assets/images/adone.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single">
                                        <div className="item-box">
                                            <div className="add-box">
                                                <img src="assets/images/adone.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </ReactOwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="recent-artivity">
                    <div className="container">
                        <h2 className="title-one">Recent Activity</h2>
                        <div className="row gy-3 gx-5">
                            <div className="col-md-4">
                                <div className="activity-bx card">
                                    <h5>
                                        M.S. Service Centre <span>Serampore, Hooghly</span>
                                    </h5>
                                    <img
                                        className="card-img-top"
                                        src="assets/images/ms-service.png"
                                        alt="Card cap"
                                    />
                                    <div className="card-body">
                                        <div className="d-flex usr">
                                            <div className="flex-shrink-0">
                                                <img src="assets/images/user-one.png" alt="..." />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h4>
                                                    Moumita Paul <span>Wrote a review</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="rating">
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/inactivestar.svg" alt="" />
                                        </div>
                                        <p className="card-text">
                                            I had contacted M.S.Service Centre for our refrigerator
                                            issue. My experience has been awesome really. They have
                                            rendered their services so promptly and excellently in every
                                            aspect.I must specially mention about the mechanic. Not only
                                            is he technically sound in identifying the exact problem,
                                            but also is very swift and a total expert in rectifying it.
                                            His punctuality is remarkable. Alongside, his extreme
                                            courteousness comes as a complimentary.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="activity-bx card">
                                    <h5>
                                        M.S. Service Centre <span>Serampore, Hooghly</span>
                                    </h5>
                                    <img
                                        className="card-img-top"
                                        src="assets/images/activity-two.png"
                                        alt="Card cap"
                                    />
                                    <div className="card-body">
                                        <div className="d-flex usr">
                                            <div className="flex-shrink-0">
                                                <img src="assets/images/activity-two.png" alt="..." />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h4>
                                                    Moumita Paul <span>Wrote a review</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="rating">
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/inactivestar.svg" alt="" />
                                        </div>
                                        <p className="card-text">
                                            I had contacted M.S.Service Centre for our refrigerator
                                            issue. My experience has been awesome really. They have
                                            rendered their services so promptly and excellently in every
                                            aspect.I must specially mention about the mechanic. Not only
                                            is he technically sound in identifying the exact problem,
                                            but also is very swift and a total expert in rectifying it.
                                            His punctuality is remarkable. Alongside, his extreme
                                            courteousness comes as a complimentary.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="activity-bx card">
                                    <h5>
                                        M.S. Service Centre <span>Serampore, Hooghly</span>
                                    </h5>
                                    <img
                                        className="card-img-top"
                                        src="assets/images/activity-three.png"
                                        alt="Card cap"
                                    />
                                    <div className="card-body">
                                        <div className="d-flex usr">
                                            <div className="flex-shrink-0">
                                                <img src="assets/images/user-one.png" alt="..." />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h4>
                                                    Moumita Paul <span>Wrote a review</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="rating">
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/inactivestar.svg" alt="" />
                                        </div>
                                        <p className="card-text">
                                            I had contacted M.S.Service Centre for our refrigerator
                                            issue. My experience has been awesome really. They have
                                            rendered their services so promptly and excellently in every
                                            aspect.I must specially mention about the mechanic. Not only
                                            is he technically sound in identifying the exact problem,
                                            but also is very swift and a total expert in rectifying it.
                                            His punctuality is remarkable. Alongside, his extreme
                                            courteousness comes as a complimentary.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="activity-bx card">
                                    <h5>
                                        M.S. Service Centre <span>Serampore, Hooghly</span>
                                    </h5>
                                    <img
                                        className="card-img-top"
                                        src="assets/images/activity-four.png"
                                        alt="Card cap"
                                    />
                                    <div className="card-body">
                                        <div className="d-flex usr">
                                            <div className="flex-shrink-0">
                                                <img src="assets/images/user-one.png" alt="..." />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h4>
                                                    Moumita Paul <span>Wrote a review</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="rating">
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/inactivestar.svg" alt="" />
                                        </div>
                                        <p className="card-text">
                                            I had contacted M.S.Service Centre for our refrigerator
                                            issue. My experience has been awesome really. They have
                                            rendered their services so promptly and excellently in every
                                            aspect.I must specially mention about the mechanic. Not only
                                            is he technically sound in identifying the exact problem,
                                            but also is very swift and a total expert in rectifying it.
                                            His punctuality is remarkable. Alongside, his extreme
                                            courteousness comes as a complimentary.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="activity-bx card">
                                    <h5>
                                        M.S. Service Centre <span>Serampore, Hooghly</span>
                                    </h5>
                                    <img
                                        className="card-img-top"
                                        src="assets/images/activity-five.png"
                                        alt="Card cap"
                                    />
                                    <div className="card-body">
                                        <div className="d-flex usr">
                                            <div className="flex-shrink-0">
                                                <img src="assets/images/user-one.png" alt="..." />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h4>
                                                    Moumita Paul <span>Wrote a review</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="rating">
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/inactivestar.svg" alt="" />
                                        </div>
                                        <p className="card-text">
                                            I had contacted M.S.Service Centre for our refrigerator
                                            issue. My experience has been awesome really. They have
                                            rendered their services so promptly and excellently in every
                                            aspect.I must specially mention about the mechanic. Not only
                                            is he technically sound in identifying the exact problem,
                                            but also is very swift and a total expert in rectifying it.
                                            His punctuality is remarkable. Alongside, his extreme
                                            courteousness comes as a complimentary.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="activity-bx card">
                                    <h5>
                                        M.S. Service Centre <span>Serampore, Hooghly</span>
                                    </h5>
                                    <img
                                        className="card-img-top"
                                        src="assets/images/activity-six.png"
                                        alt="Card cap"
                                    />
                                    <div className="card-body">
                                        <div className="d-flex usr">
                                            <div className="flex-shrink-0">
                                                <img src="assets/images/user-one.png" alt="..." />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h4>
                                                    Moumita Paul <span>Wrote a review</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="rating">
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/star.svg" alt="" />
                                            <img src="assets/images/inactivestar.svg" alt="" />
                                        </div>
                                        <p className="card-text">
                                            I had contacted M.S.Service Centre for our refrigerator
                                            issue. My experience has been awesome really. They have
                                            rendered their services so promptly and excellently in every
                                            aspect.I must specially mention about the mechanic. Not only
                                            is he technically sound in identifying the exact problem,
                                            but also is very swift and a total expert in rectifying it.
                                            His punctuality is remarkable. Alongside, his extreme
                                            courteousness comes as a complimentary.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link to="" className="load-more">
                            {' '}
                            Load More
                        </Link>
                    </div>
                </section>
                <section className="popular-brand">
                    <div className="container">
                        <h2 className="title-one">Popular Brands</h2>
                        <div className="row">
                            <ReactOwlCarousel
                                className="owl-theme owl-carousel-brandplay"
                                loop={true}
                                nav={true}
                                dots={false}
                                margin={20}
                                padding={20}
                                autoplay={true}
                                smartSpeed={2000}
                                center={false}
                                navText={[
                                    '<i class="fa-solid fa-angle-left"></i>',
                                    '<i class="fa-solid fa-arrow-right"></i>'
                                ]}
                                responsive={{
                                    0: {
                                        items: 2,
                                    },
                                    578: {
                                        items: 2,
                                    },
                                    768: {
                                        items: 2,
                                    },
                                    992: {
                                        items: 3,
                                    },
                                    1200: {
                                        items: 3,
                                    },
                                    1300: {
                                        items: 3,
                                    },
                                }}
                            >
                                <div className="single">
                                    <figure>
                                        <img src="assets/images/brand-one.png" alt="" />
                                    </figure>
                                </div>
                                <div className="single">
                                    <figure>
                                        <img src="assets/images/brand-two.png" alt="" />
                                    </figure>
                                </div>
                                <div className="single">
                                    <figure>
                                        <img src="assets/images/brand-three.png" alt="" />
                                    </figure>
                                </div>
                                <div className="single">
                                    <figure>
                                        <img src="assets/images/brand-two.png" alt="" />
                                    </figure>
                                </div>
                            </ReactOwlCarousel>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default Home;
