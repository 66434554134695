function RatingsComponent({value}){

return(
    <>
    {(()=>{
                                                                if(value == 0){
                                                                    return(
                                                                        <>
                                                                         <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                        </>
                                                                    )
                                                                }else if(value > 0 && value < 1){
                                                                    return(
                                                                        <>
                                                                        <i className="fa-solid fa-star-half"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                        </>
                                                                    )
                                                                }else if(value > 1 && value < 2){
                                                                    return(
                                                                        <>
                                                                        <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star-half"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                        </>
                                                                    )
                                                                }else if(value > 2 && value < 3){
                                                                    return(
                                                                        <>
                                                                         <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star-half"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                        </>
                                                                    )
                                                                }else if(value > 3 && value < 4){
                                                                    return(
                                                                        <>
                                                                        <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star-half"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                        </>
                                                                    )
                                                                }else if(value > 4 && value < 5){
                                                                    return(
                                                                        <>
                                                                         <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star-half"></i>
                                                                        </>
                                                                    )
                                                                }else if(value == 5){
                                                                    return(
                                                                        <>
                                                                         <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                        </>
                                                                    )
                                                                }
                                                                else if(value == 1){
                                                                    return(
                                                                        <>
                                                                         <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                        </>
                                                                    )
                                                                }
                                                                else if(value == 2){
                                                                    return(
                                                                        <>
                                                                         <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                        </>
                                                                    )
                                                                }
                                                                else if(value == 3){
                                                                    return(
                                                                        <>
                                                                         <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                        </>
                                                                    )
                                                                }
                                                                else if(value == 4){
                                                                    return(
                                                                        <>
                                                                         <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-solid fa-star"></i>
                                                                    <i className="fa-regular fa-star"></i>
                                                                        </>
                                                                    )
                                                                }
                                                                
                                                            })()}
    </>
)
}
export default RatingsComponent;