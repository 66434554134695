import { Link } from "react-router-dom";

function Sidemenu(){
    const logout = () => {
        localStorage.clear();
    }

    return(
        <>
        <div className="col-md-4 sidebar p-0">
                        <div className="list_step">
                            <ul>
                            <li>
                                    {' '}
                                    <a href="/profile">Dashboard</a>
                                </li>
                                <li>
                                    {' '}
                                    <a href="#Favourites">Favourites</a>
                                </li>
                                {/* <li>
                                    <a href="#saved">Saved</a>
                                </li> */}
                                <li>
                                    <a href="#EditProfile">Edit Profile</a>
                                </li>
                                {/* <li>
                                    <a href="#MyTransaction">My Transaction</a>
                                </li> */}
                                
                                {/* <li>
                                    <a href="#ChangeLanguage">Change Language</a>
                                </li> */}
                                <li>
                                    <a href="#MyWallet">My Wallet</a>
                                </li>
                            </ul>
                        </div>
                        <div className="list_step">
                            <ul>
                                <li>
                                    {' '}
                                    <a href="#History">History</a>
                                </li>
                                <li>
                                    <a href="#Notification">Notifications</a>
                                </li>
                                {/* <li>
                                    <a href="#CustomerService">Customer Service</a>
                                </li> */}
                                <li>
                                    <a href="#InviteFriends">Invite Friends</a>
                                </li>
                                <li>
                                    <a href="#Rewards">Rewards</a>
                                </li>
                            </ul>
                        </div>
                        <div className="list_step border-0">
                            <ul>
                                <li>
                                    {' '}
                                    <a href="#Policy">Policy</a>
                                </li>
                                <li>
                                    <a href="#Feedback">Feedback</a>
                                </li>
                                <li>
                                    <Link to="/login" onClick={logout} className="logout_btn">
                                        Logout
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
        </>
    )
}

export default Sidemenu;