import { useEffect, useState } from "react";
import apiService from "../../apiService";
import moment from 'moment';
import Sectionheader from "./Sectionheader";

function Notification(){
    const [ProfileImage,setProfileImage] = useState("assets/images/Ellipse_user.svg");
    const [p_image,setp_image] = useState("");
    const [u_name, set_u_name] = useState("John Doe");
    const [u_phone,set_u_phone] = useState("7364011112");
    const [p_img, set_p_img] = useState("assets/images/Ellipse_user.svg");
    const [notifications, set_notifications] = useState([]);


    useEffect(()=>{
        get_user_profile_data()
        get_notifications();
        
        },[])

        const get_notifications = async()=>{
            const data = await apiService.get_notifications();
           // console.log(data.data);
            set_notifications(data.data.data);
        }


        const notification_seen = async(data)=>{
            const dt = await apiService.notification_seen(data.notification_id);
            if(dt.data.status==1){
                get_notifications();
            }
        }

    const get_user_profile_data = async()=>{
        const data = await apiService.get_user_profile_data()
        if(data.status==200){
            
            if(data.data.status==1){
                set_u_name(data.data.u_data["name"]);
                set_u_phone(data.data.u_data["phone"]);
                if(data.data.u_data["profile_pic"]!=""){
                    setProfileImage(data.data.u_data["profile_pic"]);
                }
            }
        }
    }
    return(
        <>
         <div className="col-md-8 ">
                        <div className="profile_content">
                            <Sectionheader />
                            <div className="peronalInfoarea" style={{"maxHeight": "850px","overflowX": "scroll"}}>
                                {notifications && notifications.map((notification, index)=>{
                                    return(
                                        <>
                                <div class="card" style={{"border":"none","cursor":"pointer"}} onClick={()=>notification_seen(notification)}>
                                <div class="card-body"  style={{"padding":"0"}}>
                                    <div className={`row ${notification.is_seen === 0 ? 'notification_not_seen' : ''}`} style={{"border":"1px solid #00000020","padding":"7px","borderRadius":"5px","margin":"5px 0"}}>

                                    <div className="col-md-8">
                                     
                                    <p class="mb-0"><i class="fas fa-envelope mr-3" style={{"margin":"0 15px 0 0"}}></i> {notification.message}</p>
                                    
                                    </div>

                                    <div className="col-md-4" style={{"textAlign":"right"}}>
                                    <p class="mb-0">{moment(notification.created_at).format('MMMM Do YYYY, h:mm:ss a')} 
                                    {notification.is_seen==1?(<>
                                        <span><i class="fas fa-check-double" style={{"margin":"0 15px","color":"blue","fontWeight":"bold"}}></i></span>
                                    </>):(<></>)}
                                    
                                    </p>
                                       
                                    </div>

                                    </div>
                                </div>
                                </div>
                                        </>
                                    )
                                })}



                            </div>
                        </div>
                    </div>
        </>
    )
}

export default Notification;