import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";

function Contact(){
    return(
        <>
            <Innernavbar />
     <section className="our_servces">
  <div className="container">





  </div>
</section>


<div class="container contact">
    <header>
      <h1>Contact Us</h1>
      <p>Have questions? We're here to help!</p>
    </header>
    <form id="contact-form">
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" id="name" name="name" required />
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" id="email" name="email" required />
      </div>
      <div class="form-group">
        <label for="message">Message</label>
        <textarea id="message" name="message" rows="4" required></textarea>
      </div>
      <button type="submit">Submit</button>
    </form>
    <footer>
      <p>Have other inquiries? Contact us at <a href="mailto:info@reliep.com">info@reliep.com</a>.</p>
    </footer>
  </div>


 <Footer />
        </>
    )
}

export default Contact;