import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";

function RefundPolicy(){
    return(
        <>
         <Innernavbar />

         <section className="our_servces">
  <div className="container">





  </div>
</section>

    <div className="container privacy">
    <header>
    <h1>Membership Cancel or Refund Policy</h1>
    </header>
      
    <section>
      <h2>1. Overview</h2>
      <p>
        At Reliep, we are dedicated to providing exceptional membership services. If you need to cancel your membership or request a refund, please review our policy outlined below.
      </p>
</section>
<section>
      <h2>2. Membership Cancellation</h2>

      <h3>2.1. Canceling Your Membership</h3>
      <p>
        You can cancel your membership at any time. To cancel, please contact our customer service team at <a href="mailto:care@reliep.com">care@reliep.com</a> or call us at 9062000150. Be sure to provide your membership ID and any relevant details in your request.
      </p>

      <h3>2.2. Cancellation Timing</h3>
      <p>
        If you cancel your membership, it will remain active until the end of your current billing cycle. You will not be charged for subsequent billing cycles after cancellation.
      </p>
      </section>
      <section>
      <h2>3. Refund Policy</h2>

      <h3>3.1. Refund Eligibility</h3>
      <p>
        Refunds may be considered under the following conditions:
        <ul>
          <li><strong>Refund Within Initial Period</strong>: If you cancel your membership within 7 days of the initial payment and have not accessed membership benefits, you may be eligible for a full refund.</li>
          <li><strong>Service Issues</strong>: If you experience significant issues with the membership service that we cannot resolve, please contact us within 1 days. We will review your case and may offer a refund or other compensation as appropriate.</li>
        </ul>
      </p>

      <h3>3.2. Refund Process</h3>
      <p>
        To request a refund, please follow these steps:
        <ol>
          <li>Contact our customer service team at <a href="mailto:care@reliep.com">care@reliep.com</a> or 9062000150 with your refund request.</li>
          <li>Provide your membership ID and any relevant details about your issue or reason for requesting a refund.</li>
        </ol>
      </p>

      <h3>3.3. Refund Timing</h3>
      <p>
        Refunds will be processed within 7 business days of approval. The time it takes for the refund to appear in your account may vary based on your payment method and bank processing times.
      </p>

      <h3>3.4. Non-Refundable Membership Fees</h3>
      <p>
        Certain fees or memberships are non-refundable, including but not limited to:
        <ul>
          <li>Fees for already accessed benefits</li>
          <li>Customized or special memberships</li>
          <li>Memberships with a clear no-refund policy</li>
        </ul>
      </p>
      </section>
      <section>
      <h2>4. Contact Us</h2>
      <p>
        If you have any questions about our membership cancellation or refund policy, please reach out to us at:
      </p>
      <ul>
        <li><strong>Email</strong>: <a href="mailto:care@reliep.com">care@reliep.com</a></li>
        <li><strong>Phone</strong>: 9062000150</li>
        <li><strong>Mailing Address</strong>: Tarakeswar muktarpur Hooghly West Bengal 712401</li>
      </ul>
      </section>
      <section>
      <h2>5. Changes to This Policy</h2>
      <p>
        We may update this policy periodically. Any changes will be posted on this page with an updated revision date.
      </p>
      </section>
      <p><strong>Effective Date</strong>: 01-08-2024</p>
    </div>
<Footer />
        </>
    )
}
export default RefundPolicy;