import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputError from '../Components/InputError';
import { useToggle } from 'usehooks-ts';
import apiService from '../apiService';

function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [processing, toggleProcessing] = useToggle(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setErrors({});
        toggleProcessing();

        try {
            await apiService.generateOtp(email);

            toast.success('OTP sent to your email');
            navigate('/otp-verification', { state: { email } });
        } catch (error) {
            setErrors(error.response.data.errors);
        } finally {
            toggleProcessing();
        }
    };

    return (
        <section className="pt-5 pb-5 loginmain">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex flex-column align-items-center">
                            <img src="assets/images/Reliep.png" alt="" />
                            <h4>Login Or Sign up</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5 mt-2 mb-5">
                        <div className="form-area">
                            <div className="form-inner">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="mobile_code"
                                            className="form-control"
                                            placeholder="Email"
                                            name="name"
                                            value={email}
                                            onChange={e =>
                                                setEmail(e.target.value)
                                            }
                                            autoFocus
                                        />
                                        <InputError
                                            className="mt-2"
                                            message={errors.email}
                                        />
                                    </div>
                                    <span className="spmassage">
                                        We’ll send an OTP via Email
                                    </span>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexCheckDefault"
                                        >
                                            By Continuing, you agree our{' '}
                                            <a href="#!">Privacy Policy </a> and{' '}
                                            <a href="#!">T&Cs.</a>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckChecked"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexCheckChecked"
                                        >
                                            Get booking details & updates on{' '}
                                            <a href="#i" className="text-green">
                                                WhatsApp<i className="fa-brands fa-whatsapp ms-2"></i>
                                            </a>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault2"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="flexCheckDefault2"
                                        >
                                            If you are a citizen of EEa or UK, refer to our
                                            <a href="#!">Privacy Policy </a>
                                        </label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn form-submit"
                                        disabled={processing}
                                    >
                                        Get OTP
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
