import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useToggle } from "usehooks-ts";
import apiService from "../apiService";

function OtpVerifation() {
    const location = useLocation();
    const navigate = useNavigate();

    const { email } = location.state;

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [processing, toggleProcessing] = useToggle(false);

    const hangleSubmit = async (e) => {
        e.preventDefault();

        toggleProcessing();

        try {
            const response = await apiService.verifyOtp(email, otp);

            localStorage.removeItem("user-token");
            localStorage.setItem('user-token', btoa(response.data.token));

            toast.success('OTP verified successfully.');
            // navigate('/profile');
            var redirect_to = localStorage.getItem("redirect_to");
            if(redirect_to!=null){
              var red = redirect_to;
              localStorage.removeItem("redirect_to");
              navigate(red);
            }else{
            window.location.href="/profile";
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            toggleProcessing();
        }
    };

    const digitValidate = (event) => {
        const ele = event.target;
        ele.value = ele.value.replace(/[^0-9]/g, '');
    };

    const tabChange = (index) => {
        const elements = document.querySelectorAll('input');

        if (elements[index].value !== '' && index < 5) {
            elements[index + 1].select();
        } else if (elements[index].value === '' && index > 0) {
            elements[index - 1].select();
        }
    };

    const handleChangeOTP = (index, value) => {
        const otpValue = [...otp];

        otpValue[index] = value;
        setOtp(otpValue);

        tabChange(index);
    };

    return (
        <section className="pt-5 pb-5 loginmain">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex flex-column align-items-center">
                            <img src="assets/images/Reliep.png" alt="" />
                            <h4>Enter OTP</h4>
                            <h6>
                                Sent to {email}{' '}
                                <Link to="/login" className="text-decoration-underline">
                                    Edit
                                </Link>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5 mb-5">
                        <div className="form-area">
                            <div className="form-inner">
                                <form className="veryfyform">
                                    <input
                                        className="otp"
                                        type="text"
                                        onInput={digitValidate}
                                        maxLength={1}
                                        value={otp[0]}
                                        onChange={e => handleChangeOTP(0, e.target.value)}
                                        autoFocus
                                    />
                                    <input
                                        className="otp"
                                        type="text"
                                        onInput={digitValidate}
                                        maxLength={1}
                                        value={otp[1]}
                                        onChange={e => handleChangeOTP(1, e.target.value)}
                                    />
                                    <input
                                        className="otp"
                                        type="text"
                                        onInput={digitValidate}
                                        maxLength={1}
                                        value={otp[2]}
                                        onChange={e => handleChangeOTP(2, e.target.value)}
                                    />
                                    <input
                                        className="otp"
                                        type="text"
                                        onInput={digitValidate}
                                        maxLength={1}
                                        value={otp[3]}
                                        onChange={e => handleChangeOTP(3, e.target.value)}
                                    />
                                    <input
                                        className="otp"
                                        type="text"
                                        onInput={digitValidate}
                                        maxLength={1}
                                        value={otp[4]}
                                        onChange={e => handleChangeOTP(4, e.target.value)}
                                    />
                                    <input
                                        className="otp"
                                        type="text"
                                        onInput={digitValidate}
                                        maxLength={1}
                                        value={otp[5]}
                                        onChange={e => handleChangeOTP(5, e.target.value)}
                                    />
                                </form>

                                <button
                                    type="button"
                                    className="btn form-submit"
                                    onClick={hangleSubmit}
                                    disabled={processing}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OtpVerifation;