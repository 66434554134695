import { useEffect, useState } from 'react';
import Footer from '../Components/Footer';
import Sidemenu from './user/Sidemenu';
import apiService from '../apiService';
import Masterpageset from './user/Masterpageset';
import { toast } from 'react-toastify';

function Profile() {

    const [user_details,set_user_details] = useState(0);
    const [u_data, set_u_data] = useState([]);
    const [ProfileImage,setProfileImage] = useState("assets/images/Ellipse_user.svg");
    const [p_image,setp_image] = useState("");
    const [u_name, set_u_name] = useState("John Doe");
    const [u_phone,set_u_phone] = useState("7364011112");
    const [p_img, set_p_img] = useState("assets/images/Ellipse_user.svg");


useEffect(()=>{
get_user_profile_data()

},[])


const setdoc_data_click = (val) => {
    u_data["country"] = "India";
    set_u_data({ ...u_data, ...val });
  };

const get_user_profile_data = async()=>{
    const data = await apiService.get_user_profile_data()
    if(data.status==200){
        set_user_details(data.data.status);
        if(data.data.status==1){
            set_u_name(data.data.u_data["name"]);
            set_u_phone(data.data.u_data["phone"]);
            if(data.data.u_data["profile_pic"]!=""){
            set_p_img(data.data.u_data["profile_pic"]);
            }
        }
    }
}

const profile_image_upload = (e)=>{
    const file = e.target.files[0];
    setp_image(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
}


const submit_form_data = async(e)=>{
    e.preventDefault();
    const formData = new FormData();
    formData.append("data",JSON.stringify(u_data));
    formData.append('profile_image', p_image);
    // console.log(u_data);
    const res =  await apiService.user_details_add(formData);
    if(res.status==200){
        if(res.data.status==1){
        toast.success(res.data.msg);
        get_user_profile_data();
        const invite =  localStorage.getItem("invite-code");
        if(invite!=null || invite!=""){
            invite_user_check(invite)
        }
        }
    }
}


const invite_user_check = async(invite)=>{
const get_res = await apiService.invite_user(invite);
if(get_res.data.status==1){
    localStorage.removeItem("invite-code");
}
}


    return <>
        <section className="profile_main">
            <div className="container">

                
                <div className="profileTop">
                    <img className="mb-4" src="assets/images/Reliep.png" alt="" />
                    <div className="d-flex align-items-center mb-4">
                        <div className="flex-shrink-0">
                            <img src={p_img} alt="..." />
                        </div>
                        <div className="flex-grow-1 ms-3">
                            <h6 className="us_nam">{u_name} </h6>
                            <p className="phoneNo">
                                +91 {u_phone}{' '}
                                <i className="fa-solid fa-circle-check ms-2"></i>
                            </p>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <Sidemenu />



                    {user_details==2?(<>
                    <div className="col-md-8 ">
                        <div className="profile_content">
                            <div className="profile_contentTop">
                                <div className="avatar-upload">
                                    <div className="avatar-edit">
                                        <input
                                            type="file"
                                            id="imageUpload"
                                            accept=".png, .jpg, .jpeg" onChange={(e)=>{profile_image_upload(e)}}
                                        />
                                        <label htmlFor="imageUpload"></label>
                                    </div>
                                    <div className="avatar-preview">
                                        <div
                                            id="imagePreview"
                                            style={{
                                                backgroundImage: `url(${ProfileImage})`,
                                            }}
                                        ></div>
                                    </div>
                                    <h5>JOhn Doe</h5>
                                </div>
                            </div>
                            <div className="peronalInfoarea">
                                <form action="" className="PsInfoform" onSubmit={(e)=>{submit_form_data(e)}}>
                                    <div className="PsInfoformstep">
                                        <h4>Personal</h4>
                                        <ul>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Mr"
                                                        id="flexCheckDefault2"
                                                        name='pre_name' onChange={(e)=>{setdoc_data_click({
                                                            [e.target.name]: e.target.value,
                                                          })}}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Mr.
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Ms"
                                                        id="flexCheckDefault2"
                                                        name='pre_name' onChange={(e)=>{setdoc_data_click({
                                                            [e.target.name]: e.target.value,
                                                          })}}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Ms.
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        value="Mrs"
                                                        id="flexCheckDefault2"
                                                        name='pre_name' onChange={(e)=>{setdoc_data_click({
                                                            [e.target.name]: e.target.value,
                                                          })}}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Mrs.
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="First & Middle Name"
                                                    aria-label="First & Middle Name"
                                                    name='first_name'
                                                    required onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last name"
                                                    aria-label="Last Name"
                                                    required
                                                    name='last_name'
                                                    onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group veriified">
                                                    <input
                                                        type="number"
                                                        id="mobile_code"
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                        name="phone"
                                                        required
                                                        onChange={(e)=>{setdoc_data_click({
                                                            [e.target.name]: e.target.value,
                                                          })}}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email ID"
                                                    aria-label="Email ID"
                                                    required
                                                    name='email'
                                                    onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="date" className="form-control" required name='dob' onChange={(e)=>{setdoc_data_click({
                                                            [e.target.name]: e.target.value,
                                                          })}} />
                                            </div>
                                        
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example" required name='marital_status' onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                >
                                                    <option value="">Marital Status</option>
                                                    <option value="Married">Married</option>
                                                    <option value="Unmarried">Unmarried</option>
                                                    <option value="Widow">Widow</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="PsInfoformstep">
                                        <h4>Location</h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Pincode / Zip code"
                                                    aria-label="Pincode / Zip code"
                                                    required
                                                    name='pincode'
                                                    onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="City"
                                                    aria-label="Citye" required
                                                    name='city' onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Landmark"
                                                    name="landmark"
                                                    required
                                                    onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example" required name='nationality' onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                >
                                                    <option value="">Nationality</option>
                                                    <option value="Indian">Indian</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example" required name='country' onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                >
                                                    <option value="">Country of residence</option>
                                                    <option value="Afghanistan">Afghanistan</option>
                                                    <option value="Åland Islands">Åland Islands</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="American Samoa">American Samoa</option>
                                                    <option value="Andorra">Andorra</option>
                                                    <option value="Angola">Angola</option>
                                                    <option value="Anguilla">Anguilla</option>
                                                    <option value="Antarctica">Antarctica</option>
                                                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Armenia">Armenia</option>
                                                    <option value="Aruba">Aruba</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                    <option value="Bahamas">Bahamas</option>
                                                    <option value="Bahrain">Bahrain</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Barbados">Barbados</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Belize">Belize</option>
                                                    <option value="Benin">Benin</option>
                                                    <option value="Bermuda">Bermuda</option>
                                                    <option value="Bhutan">Bhutan</option>
                                                    <option value="Bolivia">Bolivia</option>
                                                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                    <option value="Botswana">Botswana</option>
                                                    <option value="Bouvet Island">Bouvet Island</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                    <option value="Burundi">Burundi</option>
                                                    <option value="Cambodia">Cambodia</option>
                                                    <option value="Cameroon">Cameroon</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Cape Verde">Cape Verde</option>
                                                    <option value="Cayman Islands">Cayman Islands</option>
                                                    <option value="Central African Republic">Central African Republic</option>
                                                    <option value="Chad">Chad</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Christmas Island">Christmas Island</option>
                                                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Comoros">Comoros</option>
                                                    <option value="Congo">Congo</option>
                                                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                                    <option value="Cook Islands">Cook Islands</option>
                                                    <option value="Costa Rica">Costa Rica</option>
                                                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="Cyprus">Cyprus</option>
                                                    <option value="Czech Republic">Czech Republic</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Djibouti">Djibouti</option>
                                                    <option value="Dominica">Dominica</option>
                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                    <option value="Ecuador">Ecuador</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="El Salvador">El Salvador</option>
                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                    <option value="Eritrea">Eritrea</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                    <option value="Faroe Islands">Faroe Islands</option>
                                                    <option value="Fiji">Fiji</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="French Guiana">French Guiana</option>
                                                    <option value="French Polynesia">French Polynesia</option>
                                                    <option value="French Southern Territories">French Southern Territories</option>
                                                    <option value="Gabon">Gabon</option>
                                                    <option value="Gambia">Gambia</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Gibraltar">Gibraltar</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Greenland">Greenland</option>
                                                    <option value="Grenada">Grenada</option>
                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                    <option value="Guam">Guam</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Guernsey">Guernsey</option>
                                                    <option value="Guinea">Guinea</option>
                                                    <option value="Guinea-bissau">Guinea-bissau</option>
                                                    <option value="Guyana">Guyana</option>
                                                    <option value="Haiti">Haiti</option>
                                                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="Hong Kong">Hong Kong</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="India" selected>India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Isle of Man">Isle of Man</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jersey">Jersey</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Kiribati">Kiribati</option>
                                                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                                    <option value="Korea, Republic of">Korea, Republic of</option>
                                                    <option value="Kuwait">Kuwait</option>
                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lesotho">Lesotho</option>
                                                    <option value="Liberia">Liberia</option>
                                                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Luxembourg">Luxembourg</option>
                                                    <option value="Macao">Macao</option>
                                                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                                    <option value="Madagascar">Madagascar</option>
                                                    <option value="Malawi">Malawi</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Maldives">Maldives</option>
                                                    <option value="Mali">Mali</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                    <option value="Martinique">Martinique</option>
                                                    <option value="Mauritania">Mauritania</option>
                                                    <option value="Mauritius">Mauritius</option>
                                                    <option value="Mayotte">Mayotte</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                                                    <option value="Monaco">Monaco</option>
                                                    <option value="Mongolia">Mongolia</option>
                                                    <option value="Montenegro">Montenegro</option>
                                                    <option value="Montserrat">Montserrat</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Mozambique">Mozambique</option>
                                                    <option value="Myanmar">Myanmar</option>
                                                    <option value="Namibia">Namibia</option>
                                                    <option value="Nauru">Nauru</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                    <option value="New Caledonia">New Caledonia</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Nicaragua">Nicaragua</option>
                                                    <option value="Niger">Niger</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Niue">Niue</option>
                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Palau">Palau</option>
                                                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                    <option value="Panama">Panama</option>
                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                    <option value="Paraguay">Paraguay</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Pitcairn">Pitcairn</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Reunion">Reunion</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russian Federation">Russian Federation</option>
                                                    <option value="Rwanda">Rwanda</option>
                                                    <option value="Saint Helena">Saint Helena</option>
                                                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                    <option value="Saint Lucia">Saint Lucia</option>
                                                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                                    <option value="Samoa">Samoa</option>
                                                    <option value="San Marino">San Marino</option>
                                                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Senegal">Senegal</option>
                                                    <option value="Serbia">Serbia</option>
                                                    <option value="Seychelles">Seychelles</option>
                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                    <option value="Somalia">Somalia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                    <option value="Sudan">Sudan</option>
                                                    <option value="Suriname">Suriname</option>
                                                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                    <option value="Swaziland">Swaziland</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                    <option value="Taiwan">Taiwan</option>
                                                    <option value="Tajikistan">Tajikistan</option>
                                                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Timor-leste">Timor-leste</option>
                                                    <option value="Togo">Togo</option>
                                                    <option value="Tokelau">Tokelau</option>
                                                    <option value="Tonga">Tonga</option>
                                                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                    <option value="Tuvalu">Tuvalu</option>
                                                    <option value="Uganda">Uganda</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="United States">United States</option>
                                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                    <option value="Uruguay">Uruguay</option>
                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                    <option value="Vanuatu">Vanuatu</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Viet Nam">Viet Nam</option>
                                                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                                                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                                                    <option value="Western Sahara">Western Sahara</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Zambia">Zambia</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Landline Number"
                                                    name="landline_number"
                                                    onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Address" required name='address' onChange={(e)=>{setdoc_data_click({
                                                        [e.target.name]: e.target.value,
                                                      })}}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="PsInfoformstep">
                                        <h4>friends and family</h4>
                                        <ul>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="flexCheckDefault2"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Mr.
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="flexCheckDefault2"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Ms.
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="flexCheckDefault2"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexCheckDefault2"
                                                    >
                                                        Mrs.
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Frist & Middle Name"
                                                    aria-label="Frist & Middle Name"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last name"
                                                    aria-label="Last Name"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        id="mobile_code2"
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                        name="name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email ID"
                                                    aria-label="Email ID"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="date" className="form-control" />
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                >
                                                    <option value="">Nationality</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select"
                                                    aria-label="Default select example"
                                                >
                                                    <option value="">Marital Status</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                    <button type="submit" className="btn savechanges">
                                        Save Changes
                                    </button>
                                    <p className="privacytext">
                                        By clicking on Save Changes, you agree to the{' '}
                                        <a href="#!">Terms & Conditions</a> &{' '}
                                        <a href="#">Privacy Policy</a> of Reliep Customer
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                    </>):(<>
                    <Masterpageset />
                    </>)}
                    

                </div>
            </div>
        </section>
        <Footer />
    </>;
}

export default Profile;