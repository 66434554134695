import { useState } from "react";
import Sectionheader from "./Sectionheader";
import apiService from "../../apiService";
import { toast } from "react-toastify";

function Feedback(){

const [feedback, setfeedback] = useState("");

const feedback_submit = async(e)=>{
e.preventDefault();

var fd = {"feedback":feedback}

const feedback_res = await apiService.feedback_submit(fd);
if(feedback_res.data==1){
    toast.success("Feedback successfully added. We'll reach you soon");
    setfeedback("");
}

}

    return(
        <>
             


                    <div className="col-md-8 ">
                <div className="profile_content">
                    <Sectionheader />
                    <div>
                        <h2 className="text-center m-4">Feedback</h2>

                        <form id="feedback-form" onSubmit={(e)=>{feedback_submit(e)}}>
    
    <div class="form-group">
     
      <textarea id="feedback" name="feedback" rows="6" required onChange={(e)=>{setfeedback(e.target.value)}} placeholder="Enter Feedback data here"></textarea>
    </div>
    <button type="submit">Submit Feedback</button>
  </form>

                      
                        </div>
                        </div>
                        </div>


        </>
    )
}
export default Feedback;