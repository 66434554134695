import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer className="page_ft">
            <div className="opt-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="opt-lft">
                                <h4>Follow us on</h4>
                                <ul className="sociallink">
                                    <li>
                                        <Link to="https://www.facebook.com/reliepindia">
                                            <img src="assets/images/facebook.png" alt="" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.instagram.com/invites/contact/?i=1typ4fqzgd57h&utm_content=s7s1nkh">
                                            <img src="assets/images/instragram.png" alt="" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="">
                                            <img src="assets/images/twitter.png" alt="" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.linkedin.com/in/reliep-india-8771a52bb?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ">
                                            <img src="assets/images/linkdin.png" alt="" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex ">
                                <Link to="" className="ms-auto d-table">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/appstore.png"
                                        alt=""
                                    />
                                </Link>
                                <Link to="" className="ms-auto d-table">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/google-play.png"
                                        alt=""
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span className="devider"></span>
            <div className="cms-area">
                <div className="container">
                    <p>
                        Welcome to Reliep, your 'one stop shop' where you are assisted with
                        day-to-day and exclusive planning and purchasing activities. We take
                        pride in our iconic customer support the fact that we own a strong
                        hold on local business information pan India.
                    </p>
                    <p>
                        Our service extends from providing address and contact details of
                        business establishments around the country, to making orders and
                        reservations for leisure, medical, financial, travel and domestic
                        purposes. We enlist business information across varied sectors like
                        Hotels, Restaurants, Auto Care, Home Decor, Personal and Pet Care,
                        etc. from all over the country.
                    </p>
                    <p>
                        ur 'Free Listing' feature gives a platform to showcase varied
                        specialities. We then furnish you with the information via phone,
                        SMS, web, App and WAP as well as, create a space for you to share
                        your experiences through our 'Rate &amp; Review' feature. Through
                        the 'Best Deals', 'Last Minute Deals' and 'Live Quotes', we make
                        sure that you are offered the best bargains in the market.
                    </p>
                    <h6>
                        Some of our services that will prove useful to you on a day-to-day
                        basis are :
                    </h6>
                    <div className="row g-3">
                        <div className="col-md-4">
                            <h5>Order Food Online</h5>
                            <p>
                                You are just three clicks away from placing an order and
                                exploring a wide range of exotic cuisines. Order food online
                                with Justdial and get your favorite food delivered at your
                                doorstep. Search for restaurants, view reviews and ratings,
                                avail discounts and order your food.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h5>Spa &amp; Salon</h5>
                            <p>
                                Skip the wait to get pampered at a spa or a salon. In a few
                                clicks, 'Book an Appointment' online through Justdial before
                                your next visit.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h5>Repair &amp; Services</h5>
                            <p>
                                Find the 'Best Deal', be it for repairing your air-conditioner,
                                getting your car serviced or cleaning your water purifier and
                                drive your utility problems away.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h5>Online Recharge/Bill Payment</h5>
                            <p>
                                With the help of this service you can stay on track in making
                                your bill payments and recharges without having to wait in a
                                queue. This includes bill payments for gas, electricity, data
                                card, DTH, landline, etc.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h5>Training Institutes</h5>
                            <p>
                                We have all types of online training and online classes here.
                            </p>
                        </div>
                    </div>
                    <p>
                        Some of the other services that can be of assistance to you for
                        leisure, health and home convenience are - Pest Control, Skin Care
                        Clinics, Painters, Laundry Services, Interior Designers, Mobile
                        Phone Repair, Vaccination Centres, Internet Service Providers, etc.
                        With an endless number of things under the sun, you can be sure this
                        willbe your 'One Stop Shop' to find everything and more.
                    </p>
                </div>
                <div className="container ">
                    <div className="collectionSegment">
                        <h5>Explore Reliep Collections</h5>
                        <p>
                            Travel &amp; Tourism - Beauty &amp; Fashion - Doctor - Food -
                            Finance - Home Services - Home &amp; Living - Education &amp;
                            Career - Recreation
                        </p>
                    </div>
                </div>
            </div>
            <span className="devider"></span>
            <div className="footerInner">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h3>Quick Links</h3>
                            <ul>
                                <li>
                                    {' '}
                                    <Link to="/about-us"> About us</Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to="/terms-conditions"> Terms & Conditions</Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to="/privacy-policy"> Privacy Policy</Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to="/refund-policy"> Refund Policy</Link>
                                </li>
                                {/* <li>
                                    {' '}
                                    <Link to=""> We're hiring</Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to=""> Testimonials</Link>
                                </li> */}
                                <li>
                                    {' '}
                                    <Link to="/contact-us"> Customer Care</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <h3 style={{ visibility: 'hidden' }}>Quick Links</h3>
                            <ul>
                                <li>
                                    {' '}
                                    <Link to="/profile#InviteFriends"> Feedback</Link>
                                </li>
                               
                              
                                {/* <li>
                                    {' '}
                                    <Link to=""> What's New</Link>
                                </li> */}
                               
                            </ul>
                        </div>
                        {/* <div className="col">
                            <h3>Reliep Verticals</h3>
                            <ul>
                                <li>
                                    {' '}
                                    <Link to=""> Travel &amp; Transport</Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to=""> All India </Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to=""> Restaurants </Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to=""> Doctors </Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to=""> Training Institutes</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <h3 style={{ visibility: 'hidden' }}>Quick Links </h3>
                            <ul>
                                <li>
                                    {' '}
                                    <Link to=""> AC Repair</Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to=""> Car Driver</Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to=""> Hotels</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <h3 style={{ visibility: 'hidden' }}>Quick Links</h3>
                            <ul>
                                <li>
                                    {' '}
                                    <Link to=""> Buy &amp; Sell </Link>
                                </li>
                                <li>
                                    {' '}
                                    <Link to=""> Bills &amp; Recharge</Link>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    <p className="copy-text">
                        Copyrights Reliep 2023. All Rights Reserved. Privacy - Terms -
                        Infringement
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;