  import axios from "axios";
  import { useEffect, useState } from "react";
  import { toast } from "react-toastify";

function GetCurrentLocation(){
    useEffect(() => {
      const getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              getLocationName(position.coords.latitude,position.coords.longitude);
             
            },
            error => {
              console.error('Error getting geolocation:', error);
              if (error.code === error.PERMISSION_DENIED) {
                toast.error('Please enable GPS permission');
              }
            },
            {
              enableHighAccuracy: true,
              maximumAge: 0 // Force the device to fetch a new location
            }
          );
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      };
  
      getLocation();
    }, []);

const getLocationName = (lat,long)=>{
  // console.log("lat is "+lat);
  // console.log("long is "+long);
    const p_city = localStorage.getItem("present-city");
    if(p_city==null || p_city==""){
    axios.get("https://geocode.maps.co/reverse?lat="+lat+"&lon="+long+"&api_key=661187d40a532743870895bpxb4f91c").then((data)=>{
       // console.log(data.data.address.city);
       if(data.data.address.city==undefined){
        localStorage.setItem("present-city",data.data.address.state);
       }else{
        localStorage.setItem("present-city",data.data.address.city);
       }
    })
    }
}

return(
    <>
    
    </>
)
}
export default GetCurrentLocation;