import ReactOwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import GetCurrentLocation from './GetCurrentLocation';

function Header() {
    return (
        <>
        <GetCurrentLocation />
        <header className="herosection">
            <div className="banner_inner">
                <ReactOwlCarousel
                    className="owl-theme"
                    loop={true}
                    nav={false}
                    dots={true}
                    margin={20}
                    autoplay={true}
                    smartSpeed={2500}
                    // autoplayTimeout: 6000,
                    center={false}
                    autoplayHoverPause={true}
                    // animateIn: true,
                    responsive={{
                        0: {
                            items: 1,
                        },
                        578: {
                            items: 1,
                        },
                        768: {
                            items: 1,
                        },
                        992: {
                            items: 1,
                        },
                        1200: {
                            items: 1,
                        },
                        1300: {
                            items: 1,
                        },
                    }}
                >
                    <div className="single">
                        <div className="itembox">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-4">
                                        <div className="banner-left">
                                            <h2>Step into serenity!</h2>
                                            <h1>Up to 20% off on Domestic Hotels</h1>
                                            <h2>+1% Reliep Reward Points.</h2>
                                            <div className="couponcode">
                                                <Link to="" className="common-button d-flex">
                                                    Book Now{' '}
                                                </Link>
                                                <span>
                                                    <p className="text-white d-inline-block"> Code :</p>{' '}
                                                    ADESTWQY
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="banner_right">
                                            <img src="assets/images/hero-one.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single">
                        <div className="itembox">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-4">
                                        <div className="banner-left">
                                            <h2>Step into serenity!</h2>
                                            <h1>Up to 20% off on Domestic Hotels</h1>
                                            <h2>+1% Reliep Reward Points.</h2>
                                            <div className="couponcode">
                                                <Link to="" className="common-button d-flex">
                                                    Book Now{' '}
                                                </Link>
                                                <span>
                                                    <p className="text-white d-inline-block"> Code :</p>{' '}
                                                    ADESTWQY
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="banner_right">
                                            <img src="assets/images/hero-one.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single">
                        <div className="itembox">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-4">
                                        <div className="banner-left">
                                            <h2>Step into serenity!</h2>
                                            <h1>Up to 20% off on Domestic Hotels</h1>
                                            <h2>+1% Reliep Reward Points.</h2>
                                            <div className="couponcode">
                                                <Link to="" className="common-button d-flex">
                                                    Book Now{' '}
                                                </Link>
                                                <span>
                                                    <p className="text-white d-inline-block"> Code :</p>{' '}
                                                    ADESTWQY
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="banner_right">
                                            <img src="assets/images/hero-one.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single">
                        <div className="itembox">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-4">
                                        <div className="banner-left">
                                            <h2>Step into serenity!</h2>
                                            <h1>Up to 20% off on Domestic Hotels</h1>
                                            <h2>+1% Reliep Reward Points.</h2>

                                            <div className="couponcode">
                                                <Link to="" className="common-button d-flex">
                                                    Book Now{' '}
                                                </Link>
                                                <span>
                                                    <p className="text-white d-inline-block"> Code :</p>{' '}
                                                    ADESTWQY
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="banner_right">
                                            <img src="assets/images/hero-one.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single">
                        <div className="itembox">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-4">
                                        <div className="banner-left">
                                            <h2>Step into serenity!</h2>
                                            <h1>Up to 20% off on Domestic Hotels</h1>
                                            <h2>+1% Reliep Reward Points.</h2>

                                            <div className="couponcode">
                                                <Link to="" className="common-button d-flex">
                                                    Book Now{' '}
                                                </Link>
                                                <span>
                                                    <p className="text-white d-inline-block"> Code :</p>{' '}
                                                    ADESTWQY
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="banner_right">
                                            <img src="assets/images/hero-one.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactOwlCarousel>
            </div>
        </header>
        </>
    );
}

export default Header;