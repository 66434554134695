import { useEffect, useState } from "react";
import Sectionheader from "./Sectionheader";
import apiService from "../../apiService";
import { toast } from "react-toastify";

function Invitefriends(){

    const [ProfileImage,setProfileImage] = useState("assets/images/Ellipse_user.svg");
    const [u_name, set_u_name] = useState("John Doe");
    const [u_phone,set_u_phone] = useState("7364011112");
    const [ref_data, set_ref_data] = useState("");


    useEffect(()=>{
        get_user_profile_data()
        
        },[])
    
    const get_user_profile_data = async()=>{
        const data = await apiService.get_user_profile_data()
        if(data.status==200){
            //console.log(data.data);
            if(data.data.status==1){
                set_u_name(data.data.u_data["name"]);
                set_u_phone(data.data.u_data["phone"]);
                set_ref_data(window.location.origin+"/invite/"+data.data.u_data["ref_code"]);
               
            }
        }
    }

    const click_invite_link_to_bookmark = (ref_data)=>{
        navigator.clipboard.writeText(ref_data);
        toast.success("Link copied");
    }


    return(
        <>
                 <div className="col-md-8 ">
                        <div className="profile_content">
                            <Sectionheader />


                            <div className="container mt-5">
    <div className="row">
      <div className="col-md-8 mx-auto">
        <div className="card referral-card">
          <img src="vector.png" className="card-img-top" alt="Referral Image" />
          <div className="card-header">
            {/* <h5 className="card-title">Referral Link 1</h5> */}
          </div>
          <div className="card-body">
            <p className="card-text ck">
                <div className="sl">Share Link</div>
                <input type="text" value={ref_data} disabled  onClick={()=>{click_invite_link_to_bookmark(ref_data)}} />
                <button className="btn btn-link share-icons ctbtn"  onClick={()=>{click_invite_link_to_bookmark(ref_data)}}><i className="fas fa-copy"></i></button>
            </p>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <button className="btn btn-link share-icons"><i className="fab fa-facebook"></i></button>
                <button className="btn btn-link share-icons"><i className="fab fa-twitter"></i></button>
                <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(ref_data)}`} target="_blank" className="btn btn-link share-icons"><i className="fab fa-whatsapp"></i></a>
                <button className="btn btn-link share-icons"><i className="fab fa-linkedin"></i></button>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
                            
                            </div>
                            </div>
        </>
    )
}

export default Invitefriends;