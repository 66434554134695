import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthRoute = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');

        if (userToken && userToken !== 'undefined') {
            setIsLoggedIn(true);
            return navigate('/');
        }

        setIsLoggedIn(false);
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    return (
        <>
            {
                !isLoggedIn ? props.children : null
            }
        </>
    );
}

export default AuthRoute;