import { useEffect, useState } from "react"
import Editprofile from "./EditProfile"
import Favourites from "./Favourites"
import Saved from "./Saved"
import Notification from "./Notification";
import Invitefriends from "./Invitefriends";
import History from "./History";
import Feedback from "./Feedback";

function Masterpageset(){
    const [get_menu, set_get_menu] = useState("");
    useEffect(()=>{
        setInterval(()=>{
            const hash = window.location.hash;
            const data = hash.slice(1);
            set_get_menu(data);
            },500)
    },[])
    return(
        <>

        {get_menu==""?(<>
        Dashboard
        </>):(<></>)}
                    {get_menu=="Favourites"?(<>
                    <Favourites />
                    </>):(<></>)}

                    {get_menu=="Saved"?(<>
                    <Saved />
                    </>):(<></>)}

                    {get_menu=="EditProfile"?(<>
                    <Editprofile />
                    </>):(<></>)}

                    {get_menu=="Notification"?(<>
                    <Notification />
                    </>):(<></>)}

                    {get_menu=="InviteFriends"?(<>
                    <Invitefriends />
                    </>):(<></>)}

                    {get_menu=="History"?(<>
                    <History />
                    </>):(<></>)}

                    {get_menu=="Feedback"?(<>
                    <Feedback />
                    </>):(<></>)}

                    {get_menu=="Policy"?(window.location.href="/privacy-policy"):(<></>)}
        </>
    )
}

export default Masterpageset