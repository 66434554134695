import React, { useState, useEffect } from 'react';
import { 
    GoogleMap, 
    DirectionsService, 
    DirectionsRenderer, 
    Marker, 
    useJsApiLoader, 
    Autocomplete } from '@react-google-maps/api';

const MapContainer = ({ from, to }) => {
  const [response, setResponse] = useState(null);


const {isLoaded} = useJsApiLoader({
    googleMapsApiKey:process.env.REACT_APP_GOOGLE_MAP_API,
    libraries:["places"]
})



  const directionsCallback = (result, status) => {
    if (status === 'OK') {
      setResponse(result);
     // console.log(result);
    } else {
      console.error(`Error fetching directions ${status}`);
    }
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMap
        mapContainerStyle={{ height: '100%', width: '100%' }}
        zoom={10}
        center={from}
      >
        <Marker position={from} />
        {from && to && (
          <DirectionsService
          options={{
            destination: to, // Odisha coordinates
            origin: from, // Kolkata coordinates
            travelMode: 'DRIVING'
          }}
            callback={directionsCallback}
          />
        )}
        {response && <DirectionsRenderer directions={response} />}
      </GoogleMap>
    </div>
  );
};

export default MapContainer;
