import { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import apiService from "../apiService";
import { Link } from "react-router-dom";

function Services(){

  const [get_services,set_get_servies] = useState([]);
  const [get_popular_services, set_get_popular_services] = useState([]);
  const [get_all_services, set_get_all_services] = useState([]);
  const [new_search_qu, set_new_search_qu] = useState([]);
  const [filtered, setfiltered] = useState(0);

useEffect(()=>{
get_services_data();
},[])


const get_search_data = (e)=>{
  
  var le = e.target.value;
  var length = le.toString();
  if(length.length==0){
    setfiltered(0)
    get_services_data();
  }else{
   // console.log(new_search_qu);
    const n_ary = new_search_qu.map((obj) => {
      if (obj[Object.keys(obj)[0]].length > 0) {
        obj["new_tmp_query"] = (Object.keys(obj)[0] + " " + obj[Object.keys(obj)[0]].map((ol) => ol.name).join(', ')).toLowerCase();
      } else {
        obj["new_tmp_query"] = ""; // Set empty string if array is empty
      }
      return obj;
    });

    const filteredData = n_ary.filter(obj => obj.new_tmp_query.toLowerCase().includes(le));
    set_get_all_services(filteredData);
   // console.log(filteredData);
    setfiltered(1)
  }
}

const get_services_data = async()=>{
  const serv = await apiService.get_services_data();
  if(serv.status==200){
   // console.log(serv.data.data);
    set_get_servies(serv.data.data);
    
    set_get_popular_services(serv.data.data.popular_services)
    set_get_all_services(serv.data.data.all_services)
    set_new_search_qu(serv.data.data.all_services);
  }
}

return(
    <>
    <Innernavbar />
    <section className="our_servces">
  <div className="container">
    <div className="input-group mb-4 border rounded-pill p-1 searchBar">
      <div className="input-group-prepend border-0">
        <button id="button-addon4" type="button" className="btn btn-link text-info"><i className="fa fa-search"></i></button>
      </div>
      <input type="search" placeholder="Search Booking Services" aria-describedby="button-addon4" className="form-control bg-none border-0" onKeyUp={(e)=>{get_search_data(e)}} />
    </div>
{filtered==0?(<>
  <h3>Services </h3>
    <h5>Popular Service</h5>
    <div className="row">


          {get_popular_services && get_popular_services.map((popular_serv,index)=>{
            return(
              <>
                    <div className="col-md-2 col-sm-6">
        <div className="">
            <div className="servicebox">
            <Link to={`/service/${popular_serv.slug}`} style={{textDecoration:"none"}}>
              <div className="serviceboxInner">
                <img src={popular_serv.icon} alt="" />
              </div>
            <h6>{popular_serv.name}</h6>

             </Link>
            </div> 
 </div>
      </div>

              </>
            )
          })}



       
    </div>
</>):(<></>)}



  </div>
</section>
<section className="our_servces">
  <div className="container">
<h3>All Services </h3>
  </div>
  </section>



  {get_all_services.map((all_serv, index)=>{
  return(
    <>
    <section className="our_servces">
  <div className="container">

    <h5>{Object.keys(all_serv)[0]}</h5>
    <div className="row">


          {all_serv && all_serv[Object.keys(all_serv)[0]].map((all_serv_data, index)=>{
            return(
              <>
                    <div className="col-md-2 col-sm-6">
        <div className="">
                          <div className="servicebox">
          <Link to={`/service/${all_serv_data.slug}`} style={{textDecoration:"none"}}>
              <div className="serviceboxInner">
                <img src={all_serv_data.icon} alt="" />
              </div>
            <h6>{all_serv_data.name}</h6>
            </Link>
            </div>
</div>
      </div>

              </>
            )
          })}
            
        
    </div>
  </div>
</section>
    </>
  );
})}
    <Footer />
    </>
)
}

export default Services;