import { Navigate, useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import { useEffect, useState } from "react";
import apiService from "../apiService";
import GetCurrentLocation from "../Components/GetCurrentLocation";
import { toast } from "react-toastify";
import RatingsComponent from "../Components/RatingComponent";
import GoogleAutocomplete from "../Components/GoogleAutocomplete";

function Service() {
    const [service, set_service] = useState("");
    const [location, set_location] = useState("");
    const [all_category_wise_data, set_all_category_wise_data] = useState([]);
    const [get_filtered_data, set_get_filtered_data] = useState([]);
    const [savedFavourites, setSavedFavourites] = useState([]);
    const { slug } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        const rep_slug = slug.replace(/-/g, ' ');
        set_service(rep_slug);
        const p_city = localStorage.getItem("present-city");
        set_location(p_city);
        get_data_specific(rep_slug, p_city);
        get_user_wise_favourite();
    }, [])

    const get_data_specific = async (slug, p_city) => {
        const res_data = await apiService.get_filtered_service_data(slug, p_city);
        if (res_data.data.status == 0) {
            toast.error(res_data.data.msg);
        } else {
            const p_city = localStorage.getItem("present-city");
            set_all_category_wise_data(res_data.data.data);
            const fil_data = res_data.data.data;
            get_location_wise_filtered_data(fil_data, p_city);


        }
    }

    const get_location_wise_filtered_data = (fil_data, location) => {
       //  console.log(location);
         let commaSplit = location.split(',');

         // Step 2: Split the first part (commaSplit[0]) by spaces
         let firstPart = commaSplit[0].trim().split(' ');
         
         // Step 3: Get the first element from the firstPart array
         let firstElement = firstPart[0];
         
        // console.log(firstElement);


        var data = [];
        var get_data = [];
        data = fil_data;
        if (data.length > 0) {
            data.forEach((k) => {
                k["tmp_address"] = k["service_address"];
            })


            var hspk = data.filter((item) => {
                // console.log(this.location_pincode);
                if (item['tmp_address'] != null) {
                    return (item['tmp_address'].toLowerCase().indexOf(firstElement.toLowerCase()) > -1);
                }
            })

            get_data = [];
            get_data = hspk;
        } else {
            get_data = [];
        }
        // console.log(get_data);
        set_get_filtered_data(get_data);
    }


    const add_favourite = async (data) => {
        const is_active_user = localStorage.getItem("user-token");
        // console.log(is_active_user);
        if (is_active_user == null) {
            localStorage.setItem("redirect_to", "/service/" + slug);
            toast.error("Please login first");
            navigate("/login");
        } else {
            const res_data = await apiService.save_to_favourite(data.services_id, is_active_user);
            // console.log(res_data.data);
            if (res_data.data.status == 1) {
                toast.success(res_data.data.msg);
            } else {
                toast.error(res_data.data.msg);
            }

            get_user_wise_favourite()


        }
    }

    const get_fav_data = ()=>{

    }

    const get_single_page = (slug)=>{
        navigate("/details/"+slug);
    }

    const get_user_wise_favourite = async()=>{
        const is_active_user = localStorage.getItem("user-token");
        if(is_active_user!=null){
            const get_favourite_saved = await apiService.get_favourite_saved_services();
            setSavedFavourites(get_favourite_saved.data.data);
            
        }
    }

    return (
        <>
            {location == "" ? (<><GetCurrentLocation /></>) : (<></>)}
            <Innernavbar />
            <section className="our_servces">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <h3>Top <span className="top_service_name">{service}</span></h3>
                        </div>
                        <div className="col-md-7">
                            <div className="d-flex">
                                <div className="input-group mb-4 border rounded-pill p-1 searchBar">
                                    <div className="input-group-prepend border-0">
                                        <button id="button-addon4" type="button" className="btn btn-link text-info"><i className="fa fa-search"></i></button>
                                    </div>
                                    <GoogleAutocomplete />
                                   
                                </div>
                                <div className="input-group mb-4 ms-3 border rounded-pill p-1 searchBar">
                                    <div className="input-group-prepend border-0">
                                        <button id="button-addon4" type="button" className="btn btn-link text-info"><i className="fa fa-search"></i></button>
                                    </div>
                                    <input type="search" placeholder="Search Booking Services" aria-describedby="button-addon4" className="form-control bg-none border-0" />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
            <div className="addbanner">
                <img className="w-100" src="/assets/images/adbanner.png" alt="" />
            </div>
            <div className="repairMainarea">
                <div className="container">
                    <nav lang="sk" role="navigation" aria-label="Omrvinková navigácia" className="breadcrumb">
                        <ol>
                            <li>
                                <a href="">{location}</a>
                            </li>
                            <span aria-hidden="true" className="breadcrumb-separator">&gt;</span>
                            <li>
                                <a href="#"><span style={{ "textTransform": "capitalize" }}>{service}</span> in {location}</a>
                            </li>
                            <span aria-hidden="true" className="breadcrumb-separator">&gt;</span>
                            <li>
                                <a href="#"><span style={{ "textTransform": "capitalize" }}>{service}</span> in {location}</a>
                            </li>
                            <span aria-hidden="true" className="breadcrumb-separator">&gt;</span>
                            <li>
                                <strong>
                                    <a href="#" aria-current="page">{get_filtered_data && (<>{get_filtered_data.length+" Listings"}</>)}</a>
                                </strong>
                            </li>
                        </ol>
                    </nav>
                    <h4>Top <span style={{ "textTransform": "capitalize", "fontWeight": "500" }}>{service}</span> in {location}, {location}</h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="reapir_wrapper">
                                <div className="filtercontainer">
                                    <div className="filter">
                                        <select id="sort" className="commonflbtn">
                                            <option value="default">Short By</option>
                                            <option value="price">Price</option>
                                            <option value="name">Name</option>
                                            <option value="date">Date</option>
                                        </select>
                                    </div>
                                    <button className="commonflbtn"> <img src="/assets/images/blckStar.svg" alt="" /> Top Rated</button>
                                    <button className="commonflbtn"> <img src="/assets/images/timeclock.png" alt="" /> Quick Response</button>
                                    <div className="filter ">
                                        <select id="sort" className="commonflbtn">
                                            <option value="default">Brand</option>
                                            <option value="price">Price</option>
                                            <option value="name">Name</option>
                                            <option value="date">Date</option>
                                        </select>
                                    </div>
                                    <button className="commonflbtn"> Reliep Verified </button>
                                    <div className="filter">
                                        <select id="sort" className="commonflbtn">
                                            <option value="default">Ratings</option>
                                            <option value="price">Price</option>
                                            <option value="name">Name</option>
                                            <option value="date">Date</option>
                                        </select>
                                    </div>
                                    <button className="commonflbtn"> <img src="/assets/images/filterIco.png" alt="" /> All Filters </button>
                                </div>

                                {get_filtered_data.length > 0 ? (
                                    <>
                                        {get_filtered_data.map((gfd, indx) => {
                                            return (
                                                <>
                                                    <div className="servicItem mb-3">

                                                        <div className="row p-3">
                                                            <div className="col-md-2">
                                                                {/* <div className="owl-carousel repLslider">

                    <div className="single">
                      <div className="itembox">
                        <img src={gfd.featured_image} alt="" />
                      </div>
                    </div>
                   
                  </div> */}
                                                                <div className="repLslider">

                                                                    <div className="single">
                                                                        <div className="itembox">
                                                                            <img src={gfd.featured_image} alt="" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h5><span onClick={()=>get_single_page(gfd.slug)} style={{"cursor":"pointer"}}>{gfd.service_name}</span> 
                                                                    <i className={`fa-regular fa-heart ms-auto ${savedFavourites && savedFavourites.includes(gfd.services_id) ? 'heart-active' : ''}`} onClick={() => { add_favourite(gfd) }} style={{ cursor: 'pointer'}}></i>
                                                                    
                                                                    </h5>
                                                                    <div className="ratingproc">
                                                                        <span className="rtno">{gfd.ratings_average}</span>
                                                                        <div className="starts">
                                                                            <RatingsComponent value={gfd.ratings_average} />
                                                                           
                                                                        </div>
                                                                        {gfd.rating_count} Rating{gfd.rating_count == 0 ? (<></>) : (<>s</>)}
                                                                    </div>
                                                                    <div className="locationtext"><i className="fa-solid fa-location-dot me-2"></i>{gfd.service_address}</div>
                                                                    <div className="redirbtn"><a href="#">{gfd.category_name}</a></div>
                                                                    <div className="btnGp">
                                                                        <button type="button" className="btn btn-success"><i className="fa-solid fa-phone me-2"></i><span className="mobile_hide">{gfd.contact_no}</span></button>
                                                                        <button type="button" className="btn btn-primary" > Send Book</button>
                                                                        <button type="button" className="btn whatsapp"><i className="fa-brands fa-whatsapp"></i><span className="mobile_hide"> Chat</span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </>
                                ) : (<></>)}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
export default Service;